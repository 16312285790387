<template>
  <Card class="contractor-details-card !tw-py-4 !tw-px-4 !tw-box-border">
    <template #content>
      <main class="tw-flex tw-flex-col">
        <div
          class="tw-flex tw-items-center tw-justify-start tw-gap-2 tw-relative"
        >
          <ProfileThumbnail :src="contractorWorkStationUrl" />
          <div
            class="verified__icon tw-absolute tw-left-[30px] tw-top-[-10px] tw-w-[24px] tw-h-[24px] tw-rounded-lg tw-flex tw-items-center tw-justify-center tw-shadow-[0px_0px_10px_0px_#0C0F4A33] tw-bg-white"
          >
            <img src="../../../../assets/icons/verified.svg" alt="" />
          </div>
        </div>

        <div
          class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-2 tw-mt-4"
        >
          <Text whiteSpace="nowrap" variant="h4">
            {{ contractorDetails?.name }}
          </Text>
          <div class="tw-flex tw-items-center tw-justify-start">
            <Text
              variant="span"
              textColor="rgba(12, 15, 74, 0.5)"
              textWeigth="600"
              whiteSpace="nowrap"
              >{{ contractorDetails?.user?.userBusinessDetails?.type }}</Text
            >
            <Postcode :value="contractorDetails?.user?.postcode" />
            <ChipState :hasCustomLabel="true">
              <template #prefix>
                <!-- <v-rating
                  active-color="orange"
                  v-model="rating"
                  color="rgba(12, 15, 74, 0.26)"
                  empty-icon="mdi-star"
                  readonly
                ></v-rating> -->
                <RatingIcon />
              </template>
              <template #custom-label>
                <Text variant="span" textWeight="400" whiteSpace="nowrap">
                  {{ getAverageOfAllProjectReview }}/5
                </Text>
              </template>
            </ChipState>
          </div>
          <div class="tw-flex tw-items-center tw-justify-start tw-gap-2">
            <!-- <ChipState :hasCustomLabel="true" bgColor="rgba(12, 15, 74, 0.04)">
              <template #prefix>
                <CheckGreen class="tw-w-[16px] tw-h-[16px]" />
              </template>
              <template #custom-label>
                <Text variant="span" textWeight="400" whiteSpace="nowrap">
                  <span class="!tw-text-[#1FB27A] !tw-font-bold">
                    {{
                      contractorDetails?.experience
                        ? `${contractorDetails?.experience} + Yrs `
                        : "N/A"
                    }}
                  </span>
                  Exp.
                </Text>
              </template>
            </ChipState> -->
            <CustomBadge v-if="getUserSkillList && getUserSkillList.length">
              <span
                class="tw-text-[12px] tw-font-[700] tw-text-[#0C0F4A] tw-flex tw-gap-1 tw-items-center tw-px-2 tw-py-1 tw-bg-[#0C0F4A0A] tw-rounded-[8px]"
                v-for="skill in getUserSkillList.slice(0, 1)"
                :key="skill.id"
              >
                <v-icon
                  class="!tw-w-[16px] !tw-h-[16px] tw-rounded-full tw-bg-[#0C0F4A] tw-overflow-hidden"
                >
                  <img
                    class="tw-w-[12px] tw-h-[12px] tag__img"
                    v-if="skill?.iconName"
                    :src="
                      require('@/assets/category-icons/' +
                        skill?.iconName +
                        '.svg')
                    "
                /></v-icon>
                <p>{{ skill?.label }}</p>
              </span>
              <span></span>
            </CustomBadge>
            <span
              v-if="getUserSkillList?.length > 1"
              class="tw-text-[12px] tw-font-[700] tw-text-[#0C0F4A] tw-flex tw-gap-1 tw-items-center tw-px-2 tw-py-1 tw-bg-[#0C0F4A0A] tw-rounded-[8px]"
            >
              +{{ getUserSkillList?.length - 1 }}
            </span>
          </div>
        </div>
        <v-divider class="tw-my-4"></v-divider>
        <div class="tw-w-full tw-flex tw-items-center tw-justify-end">
          <Button
            @click="viewTradePassport"
            class="!tw-w-auto !tw-px-0 !tw-py-0 tw-box-border !tw-text-[#4B4BFF] !tw-capitalize !tw-font-normal !tw-tracking-[0.15px] !tw-h-auto"
            variant="p"
            label="View Trade Passport"
          >
            <template #postfix>
              <v-icon icon="mdi-chevron-right"></v-icon>
            </template>
          </Button>
        </div>
      </main>
    </template>
  </Card>
</template>
<script lang="ts" setup>
import Card from "@/core/components/ui/general/Card.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import Text from "@/core/components/ui/general/Text.vue";
import RatingIcon from "@/core/components/icons/RatingIcon.vue";
import CheckGreen from "@/core/components/icons/CheckGreen.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Postcode from "@/core/uni-components/Chips/Postcode.vue";
import CountChip from "@/core/uni-components/Chips/CountChip.vue";
import { computed, ref } from "vue";
import CustomBadge from "@/core/uni-components/CustomBadge.vue";
import { onMounted } from "vue";
import {
  getImageApiUrl,
  getImageStringToImageURL,
  getUserSubSkillList,
} from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { TRADE_ESTIMATES_TRADE_PASSPORT } from "../../routes";
import { TRADE_ESTIMATE_STORE } from "@/store/modules/trade-estimate";
import { reviewRatingState } from "@/core/models/project-rating";
import { REVIEW_RATING_STORE } from "@/store/modules/review-rating";
import { getCombineSameProjectReview } from "@/core/utils/common";

const props = defineProps({
  contractorDetails: {
    type: Object,
  },
});
const contractorWorkStationUrl = ref(null);

const store = useStore();
const router = useRouter();
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const getAllSpecialism = computed(
  () => store.getters[`${USER_STORE}/getAllSpecialism`]
);
const getUserSkillList = computed(() => {
  return getUserSubSkillList(
    props.contractorDetails?.user?.userSkills,
    getAllSpecialism.value
  );
});

const getAllProjectReviewList = computed<
  reviewRatingState["allProjectReviewList"]
>(() => store.getters[`${REVIEW_RATING_STORE}/getAllProjectReviewList`]);

const combineSameProjectReview = computed(() =>
  getCombineSameProjectReview(getAllProjectReviewList.value)
);
const getAverageOfAllProjectReview = computed(() => {
  const totalAvg = combineSameProjectReview.value.reduce(
    (totalRating: number, review: any) => {
      totalRating += review.totalAvg || 0;
      return totalRating;
    },
    0
  );
  return totalAvg
    ? Math.round((totalAvg * 100) / combineSameProjectReview.value.length) / 100
    : totalAvg;
});

onMounted(async () => {
  try {
    if (!getAllSpecialism.value.length) {
      await store.dispatch(
        `${USER_STORE}/setAllSpecialismsCategory`,
        activeUserWorkstation.value.id
      );
    }

    contractorWorkStationUrl.value = await getImageStringToImageURL(
      props?.contractorDetails?.profileImage
    );
  } catch (error) {
    console.log();
  }
});

const viewTradePassport = async () => {
  if (props.contractorDetails?.id) {
    store.commit(
      `${TRADE_ESTIMATE_STORE}/setContractorTradePassportWorkstationId`,
      props.contractorDetails?.id
    );

    router.push({
      name: TRADE_ESTIMATES_TRADE_PASSPORT,
    });
  }
};
</script>
<script lang="ts">
export default {
  name: "ContractorDetailsCard",
};
</script>
<style lang="scss" scoped>
.contractor-details-card {
  box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
}

:deep(.v-btn:hover) > .v-btn__overlay {
  opacity: 0;
}
.tag__img {
  filter: invert(99%) sepia(0%) saturate(3735%) hue-rotate(87deg)
    brightness(119%) contrast(100%);
}
</style>
