<template>
  <GeneralWidgetLayoutSlot
    :getUserWorkStationFieldAccess="getUserWorkStationFieldAccess"
    class="!tw-min-h-[450px]"
    title="Jobs Overview"
    buttonLabel="Manage Jobs"
    :borderBottom="true"
    @on-action-click="onClickGotoJob"
    :withBorder="true"
    :isRoundedxl="true"
  >
    <template #content>
      <main class="tw-w-full tw-h-full tw-flex tw-justify-between tw-flex-col">
        <div
          class="tw-flex tw-items-center tw-justify-between tw-py-2 tw-px-4 tw-box-border"
          :class="{ 'tw-border-b-solid-custom': !item?.noBorder }"
          v-for="(item, index) in items"
          :key="index"
        >
          <div
            class="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer"
            @click="onClickShow(item)"
          >
            <component :is="item.icon" />
            <Text variant="p" textWeight="500">{{ item.title }}</Text>
          </div>
          <Text class="!tw-text-[20px]" textWeight="600">{{
            String(
              item.title === "Verify Jobs"
                ? verifyJobCount || 0
                : getJobCountBaseOnStatus(item.statusId)
            )
          }}</Text>
        </div>
      </main>
    </template>
  </GeneralWidgetLayoutSlot>
</template>
<script setup>
import { reactive, computed, onMounted } from "vue";

import GeneralWidgetLayoutSlot from "@/core/components/slots/GeneralWidgetLayoutSlot.vue";
import Text from "@/core/components/ui/general/Text.vue";
import InProgressIcon from "@/core/components/icons/InProgressIcon.vue";
import EstimateReceivedIcon from "@/core/components/icons/EstimateReceivedIcon.vue";
import EstimateSentIcon from "@/core/components/icons/EstimateSentIcon.vue";
import NotStartedIcon from "@/core/components/icons/NotStartedIcon.vue";
import DraftJobsIcon from "@/core/components/icons/DraftJobsIcon.vue";
import { JOBS_STORE } from "@/store/modules/jobs";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ProjectJobStatusEnum } from "@/core/enums/ProjectsEnum";
import { JOBS_ROUTE } from "@/modules/jobs/routes";
const props = defineProps({
  getUserWorkStationFieldAccess: {
    type: Boolean,
    default: true,
  },
});
const inProgress = InProgressIcon;
const estimateReceived = EstimateReceivedIcon;
const estimateSent = EstimateSentIcon;
const notStarted = NotStartedIcon;
const draftJobs = DraftJobsIcon;
const store = useStore();
const router = useRouter();

const verifyJobCount = computed(() => {
  return store.getters[`${JOBS_STORE}/getHistoricalJobs`]?.length;
});
const items = reactive([
  {
    icon: inProgress,
    title: "In progress",
    statusId: [ProjectJobStatusEnum.IN_PROGRESS],
    count: "0",
  },

  {
    icon: estimateSent,
    title: "Estimates sent",
    statusId: [
      ProjectJobStatusEnum.QUOTES_RECEIVED,
      ProjectJobStatusEnum.IN_PROGRESS,
    ],
    count: "0",
  },
  {
    icon: notStarted,
    title: "Not Started",
    statusId: [ProjectJobStatusEnum.QUOTES_RECEIVED],
    count: "0",
  },
  {
    icon: draftJobs,
    title: "Draft Jobs",
    statusId: [ProjectJobStatusEnum.DRAFT_PROJECT],
    count: "0",
  },
  {
    icon: draftJobs,
    title: "Verify Jobs",
    count: "0",
    noBorder: true,
  },
]);
const getJobStatusCountsList = computed(
  () => store.getters[`${JOBS_STORE}/getJobStatusCountsList`]
);
const getJobCountBaseOnStatus = (statusId) => {
  try {
    return statusId.reduce((count, id) => {
      count += getJobStatusCountsList.value[id] || 0;
      return count;
    }, 0);
  } catch (error) {
    return "0";
  }
};
const onClickShow = (item) => {
  if (item.title === "Verify Jobs") {
    return router.push(`/verify-jobs`);
  }
  return;
};
const onClickGotoJob = () => {
  router.push({ name: JOBS_ROUTE });
};
onMounted(async () => {
  if (!Object.keys(getJobStatusCountsList.value)?.length) {
    await store.dispatch(`${JOBS_STORE}/setJobStatusCounts`);
    // console.log(getJobStatusCountsList.value, "getJobStatusCountsList");
  }
});
</script>
<style lang="scss" scoped></style>
