<template>
  <div
    v-if="isLoading"
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[530px]"
  >
    <Loader :show="isLoading" />
  </div>
  <div
    class="trade__insurance__wrapper md:!tw-pb-[4rem] md:!tw-box-border"
    v-if="!isLoading && userInsurances?.length"
  >
    <PendingVerifyNotice
      v-if="isShowPendingBoard"
      content="New insurance under review. Existing coverage valid."
    />
    <TradeWelcomeBoard
      title="Insurances"
      description="Upload your insurance details to ensure compliance and build trust"
      actionTitle="add new insurance"
      @onClickAction="onClickAddNewInsurance"
    />
    <div class="trade__certification__wrapper">
      <div class="trade__header">
        <h4 v-if="isUserProprtyOwner">Insurances</h4>
        <h4 v-else>Your Insurances</h4>
      </div>
      <div class="trade-passport-row">
        <v-card
          class="custom-card"
          v-for="insurance in userInsurances"
          :key="insurance.id"
        >
          <div>
            <div class="insurances-list">
              <div class="insurances_list__header">
                <PendingChip
                  class="tw-w-[100px]"
                  label="Pending"
                  v-if="
                    insurance?.userVerificationStatus ===
                    UserVerificationStatusEnum.PENDING_VERIFICATION
                  "
                />
                <AcceptChip
                  class="tw-w-[100px]"
                  label="Accepted"
                  v-if="
                    insurance?.userVerificationStatus ===
                      UserVerificationStatusEnum.VERIFIED && !isUserProprtyOwner
                  "
                />
                <AcceptChip
                  class="tw-w-[100px]"
                  label="Verified"
                  v-if="
                    insurance?.userVerificationStatus ===
                      UserVerificationStatusEnum.VERIFIED && isUserProprtyOwner
                  "
                />
                <RejectChip
                  class="tw-w-[100px]"
                  label="Expired"
                  v-if="
                    insurance?.userVerificationStatus ===
                    UserVerificationStatusEnum.REJECTED
                  "
                />
                <div
                  class="insurances-list__item-action tw-flex tw-gap-1 tw-items-center rmd:tw-gap-3"
                  v-if="getUserWorkStationFieldAccess"
                >
                  <v-icon
                    icon="mdi-pencil"
                    color="#80829F"
                    size="s-small"
                    class="card__edit"
                    @click="editInsurance(insurance)"
                  ></v-icon>

                  <v-icon
                    icon="mdi-delete"
                    color="#80829F"
                    size="s-small"
                    class="card__delete"
                    @click="onDeleteItem(insurance.id)"
                  ></v-icon>
                </div>
              </div>

              <ItemListCard
                :isShowEditButton="false"
                :isShowDeleteButton="false"
              >
                <template v-slot:listItemCardTitle>
                  {{
                    insurance?.metadata?.insuranceName ||
                    insurance?.insurance?.name
                  }}
                  <div
                    class="prefix"
                    v-if="isExpiredDate(insurance?.metadata?.expiryDate)"
                  >
                    <v-icon>
                      <img src="../../../assets/icons/exclaimation.svg" />
                    </v-icon>
                    Expired
                  </div>
                </template>
                <template v-slot:listItemCardLabel
                  >Coverage: £{{
                    formatSum(insurance?.metadata?.sum)
                  }}</template
                >
                <template v-slot:listItemCardText
                  >Expiry:
                  {{ formatDate(insurance?.metadata?.expiryDate) }}</template
                >
              </ItemListCard>
              <div v-if="insurance?.attachment?.length" class="pdf__uploader">
                <span>
                  {{ insurance?.attachment[0]?.originalName }}
                </span>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
  <div
    v-if="!isLoading && !userInsurances?.length"
    class="empty__content !tw-pb-12 !tw-box-border !tw-h-auto"
  >
    <div
      class="empty__banner tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
    >
      <div class="empty__banner__img">
        <img
          :src="require('../../../assets/images/trade_insurance_empty.png')"
          alt=""
          class="tw-w-full"
        />
      </div>
      <div v-if="!isUserProprtyOwner" class="empty__banner__content">
        <h4>You don't have any Insurance yet!</h4>
        <p>
          You haven't added any insurance details yet. Upload your insurance to
          build trust and meet industry standards."
        </p>
      </div>
      <div v-else class="empty__banner__content">
        <h4>Don't have any Insurance yet!</h4>
        <p>haven't added any insurance details to this profile yet</p>
      </div>

      <div class="empty__banner__footer tw-w-full">
        <v-btn
          @click="onClickAddNewInsurance"
          v-if="getUserWorkStationFieldAccess"
          class="button button-purple"
          width="100%"
          >add new insurance</v-btn
        >
      </div>
    </div>
  </div>
  <InsuranceModel
    v-if="isShowInsuranceModal"
    @onClickCloseTab="onClickCloseModel"
    :userInsuranceData="userInsuranceData"
    :isOuterEditInsurance="isOuterEditInsurance"
    @clearUserInsuranceData="clearData"
  />

  <ConfirmDeleteModal
    v-if="isOpenConfirmDelete"
    type="insurance"
    label="You're about to delete your insurance."
    @on-close="onCloseConfirmDelete"
    @on-delete="deleteItem"
    :loading="deleteLoader"
  ></ConfirmDeleteModal>
</template>

<script lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import ItemListCard from "@/core/components/ItemListCard.vue";
import InsuranceModel from "@/modules/insurance/components/InsuranceModel.vue";
import { WORKSTATION } from "@/store/modules/workstation";
import { isExpiredDate } from "@/core/utils/common";
import {
  UserRolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import AcceptChip from "@/modules/dashboard/components/Chips/AcceptChip.vue";
import RejectChip from "@/modules/dashboard/components/Chips/RejectChip.vue";
import PendingChip from "@/modules/dashboard/components/Chips/PendingChip.vue";
// import CommonChip from "@/modules/dashboard/components/Chips/CommonChip.vue";
import ConfirmDeleteModal from "@/modules/dashboard/components/Modals/ConfirmDeleteModal.vue";
import insurancesService from "@/core/services/insurances.service";
import { INSURANCE_STORE } from "@/store/modules/insurance";
import Loader from "@/core/components/common/Loader.vue";
import TradeWelcomeBoard from "./cards/TradeWelcomeBoard.vue";
import PendingVerifyNotice from "./cards/PendingVerifyNotice.vue";

export default {
  components: {
    ItemListCard,
    InsuranceModel,
    AcceptChip,
    RejectChip,
    PendingChip,
    // CommonChip,
    ConfirmDeleteModal,
    TradeWelcomeBoard,
    Loader,
    PendingVerifyNotice,
  },
  setup() {
    const store = useStore();
    const isShowInsuranceModal = ref(false);

    const isOpenConfirmDelete = ref(false);
    const confirmDeleteId = ref(null) as any;

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const deleteLoader = ref(false);
    const userInsuranceData = ref(null) as any;
    const isLoading = ref(false);
    const userInsurances = computed(
      () => store.getters[`${USER_STORE}/userInsurances`]
    );
    const isUserProprtyOwner = computed(
      () => user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER
    );
    const formatSum = (sum: any) => {
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(sum);
    };

    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const isWorkStationVerified = computed(() => {
      return (
        store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus === UserVerificationStatusEnum.VERIFIED
      );
    });
    const isOuterEditInsurance = ref(false);
    const getUserWorkStationFieldAccess = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
    );
    onBeforeMount(async () => {
      try {
        isLoading.value = true;
        if (user.value?.id && !userInsurances.value?.length) {
          await store.dispatch(
            `${USER_STORE}/setUserInsurances`,
            user.value.id
          );
        }
      } catch (error) {
        console.log();
      } finally {
        isLoading.value = false;
      }
    });

    const onClickAddNewInsurance = () => {
      isShowInsuranceModal.value = true;
    };
    const onClickCloseModel = () => {
      isShowInsuranceModal.value = false;
    };
    const onDeleteItem = (id: any) => {
      confirmDeleteId.value = id;
      isOpenConfirmDelete.value = true;
    };

    const onCloseConfirmDelete = () => {
      confirmDeleteId.value = null;
      isOpenConfirmDelete.value = false;
    };
    const onDeleteInsurance = async (insuranceId: string) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserInsurance`, {
          userId: user.value.id,
          userInsuranceId: insuranceId,
        });
        if (userInsurances.value.length === 0 && isWorkStationVerified.value) {
          const responce: any = await insurancesService.isInsuranceExpired(
            user.value.id
          );

          store.commit(
            `${INSURANCE_STORE}/setInsuranceExpired`,
            responce.isFreezed
          );
        }
        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };
    const deleteItem = async () => {
      deleteLoader.value = true;
      await onDeleteInsurance(confirmDeleteId.value);
      deleteLoader.value = false;
      onCloseConfirmDelete();
    };
    const editInsurance = (insurance: object) => {
      isShowInsuranceModal.value = true;
      userInsuranceData.value = insurance;
      isOuterEditInsurance.value = true;
    };
    const clearData = () => {
      userInsuranceData.value = null;
    };
    const chipLabel = (status: any) => {
      if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
        return "Pending";
      else if (UserVerificationStatusEnum.REJECTED === status)
        return "Rejected";
      else if (UserVerificationStatusEnum.VERIFIED === status)
        return "Verified";
      else if (UserVerificationStatusEnum.NOT_ADDED === status)
        return "Not added";
    };
    const chipColor = (status: any) => {
      if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
        return "#FAA500";
      else if (UserVerificationStatusEnum.REJECTED === status) return "red";
      else if (UserVerificationStatusEnum.VERIFIED === status) return "green";
      else if (UserVerificationStatusEnum.NOT_ADDED === status)
        return "#7467B7";
    };

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1;
      const year = date.getUTCFullYear();
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;
      return `${formattedDay}/${formattedMonth}/${year}`;
    };

    const isShowPendingBoard = computed(
      () =>
        isWorkStationVerified.value &&
        userInsurances.value?.some(
          (insurance: any) =>
            insurance?.userVerificationStatus ===
            UserVerificationStatusEnum.PENDING_VERIFICATION
        ) &&
        userInsurances.value?.some(
          (insurance: any) =>
            insurance?.userVerificationStatus ===
            UserVerificationStatusEnum.VERIFIED
        )
    );
    return {
      onClickAddNewInsurance,
      userInsurances,
      onDeleteInsurance,
      isShowInsuranceModal,
      onClickCloseModel,
      activeUserWorkstation,
      isExpiredDate,
      editInsurance,
      userInsuranceData,
      isOuterEditInsurance,
      chipLabel,
      chipColor,
      UserVerificationStatusEnum,
      clearData,
      formatSum,
      isOpenConfirmDelete,
      deleteItem,
      onDeleteItem,
      onCloseConfirmDelete,
      getUserWorkStationFieldAccess,
      formatDate,
      deleteLoader,
      isLoading,
      isUserProprtyOwner,
      isShowPendingBoard,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";
</style>

<div>
    <slot name="listItemLogo"></slot>
  </div>
