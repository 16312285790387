<template>
  <div
    v-if="isLoading"
    class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-mt-[530px]"
  >
    <Loader :show="isLoading" />
  </div>
  <div
    class="tradepassport_certificate__dashboard md:!tw-pb-[4rem] md:!tw-box-border"
    v-if="!isLoading && userCertificates?.length"
  >
    <PendingVerifyNotice
      v-if="isShowPendingBoard"
      content="New certificates are being verified. Use old ones."
    />
    <TradeWelcomeBoard
      title="Professional Certifications"
      description=" Showcase your professional certifications to build trust and credibility."
      actionTitle="add new certification"
      @onClickAction="onClickAddNewCertification"
    />
    <div class="trade__certification__wrapper">
      <div class="trade__header">
        <h4 v-if="isUserProprtyOwner">Certifications</h4>
        <h4 v-else>Your Certifications</h4>
      </div>

      <div class="trade__certification__row">
        <v-card
          class="trade-certi-card"
          v-for="certificate in userCertificates"
          :key="certificate.id"
        >
          <div class="itemlist_card">
            <div class="itemlist_card__header">
              <div
                class="itemlist_card__action"
                v-if="getUserWorkStationFieldAccess"
              >
                <v-icon
                  icon="mdi-pencil"
                  color="#80829F"
                  class="card__edit"
                  @click="editCertificate(certificate)"
                ></v-icon>
                <v-icon
                  icon="mdi-delete"
                  color="#80829F"
                  class="card__delete"
                  @click="onDeleteItem(certificate)"
                ></v-icon>
              </div>

              <div class="title">
                <h5>
                  {{
                    certificate.metadata.name
                      ? certificate.metadata.name
                      : certificate.certificate.name
                  }}
                </h5>
                <p>
                  Membership No: {{ certificate?.metadata?.registrationNumber }}
                </p>
              </div>
            </div>
            <div class="filename" v-if="certificate?.attachment?.length">
              <img src="../../../assets/icons/pdficon.svg" alt="" />
              <p>{{ certificate?.attachment[0]?.originalName }}</p>
            </div>
            <div class="members_wrapper" v-if="certificate?.assignedUsers">
              <h5>
                Members <span>({{ certificate.assignedUsers?.length }})</span>
              </h5>

              <!-- Members List -->
              <div class="members__list">
                <div
                  class="members__list__info"
                  v-for="data in certificate.assignedUsers.slice(0, 4)"
                  :key="data.id"
                >
                  <div class="members_img">
                    <img
                      v-if="data?.userPublicProfile?.publicUrl"
                      :src="
                        data.userPublicProfile.publicUrl ||
                        '../../../assets/images/avatar-info-image.png'
                      "
                      alt="user-image"
                    />
                    <UserProfileLogo
                      v-else
                      :userName="`${data?.firstName} ${data?.lastName}`"
                    />
                  </div>
                  <h6>{{ data.firstName }} {{ data.lastName }}</h6>
                </div>
                <div
                  class="members__list__more"
                  v-if="certificate.assignedUsers?.length > 5"
                >
                  4+
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>

      <!-- <ItemListCard
            :onClickDelete="() => onDeleteItem(certificate.id)"
            :onClickEdit="() => editCertificate(certificate)"
            :isShowLogo="true"
          >
            <template v-slot:listItemLogo>
              <div class="logo_wrapper">
                <img src="../../../assets/icons/tools.svg" alt="" />
              </div>
            </template>
            <template v-slot:listItemCardTitle>{{
              certificate.certificate.name
            }}</template>
            <template v-slot:listItemCardLabel>{{
              certificate?.metadata?.registrationNumber
            }}</template>
            <template v-slot:listItemCardText
              >{{ certificate?.metadata?.url }}
              <div v-if="certificate?.attachment?.length" class="upload_dox">
                {{ certificate?.attachment[0]?.originalName }}
              </div></template
            >
          </ItemListCard> -->
      <!-- <label class="blue-text btn-xs btn-blue-hover">
              Verify Certification<v-icon icon="mdi-chevron-right"
            /></label> -->

      <!-- </v-col> -->
    </div>
  </div>
  <div
    v-if="!isLoading && !userCertificates?.length"
    class="empty__content !tw-pb-12 !tw-box-border !tw-h-auto"
  >
    <div
      class="empty__banner tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4"
    >
      <div class="empty__banner__img">
        <img
          :src="require('../../../assets/images/trade-certificate-empty.png')"
          alt=""
          class="tw-w-full"
        />
      </div>
      <div v-if="!isUserProprtyOwner" class="empty__banner__content">
        <h4>You don't have any certification yet!</h4>
        <p>
          You haven't added any certifications yet. Add them to build trust and
          showcase your qualifications to property owners.
        </p>
      </div>
      <div v-else class="empty__banner__content">
        <h4>Don't have any certification yet!</h4>
        <p>haven't added any certifications to this profile yet.</p>
      </div>

      <div class="empty__banner__footer tw-w-full">
        <v-btn
          @click="onClickAddNewCertification"
          v-if="getUserWorkStationFieldAccess"
          class="button button-purple"
          width="100%"
          >add new Certification</v-btn
        >
      </div>
    </div>
  </div>
  <CertificationModel
    v-if="isShowCertificateModal"
    @onClickCloseTab="onClickCloseModel"
    :userCertificateData="userCertificateData"
    @clearUserCertificateData="clearData"
  />

  <CertificateEditModal
    v-if="isEditCertificateModal"
    @onSaveData="onClickUpdateModal"
    @onClickCloseTab="onClickCloseEditModal"
    :userCertificateData="userCertificateData"
  />

  <ConfirmDeleteModal
    v-if="isOpenConfirmDelete"
    :type="confirmDeleteCertificate.name"
    label="You're about to delete your certification."
    @on-close="onCloseConfirmDelete"
    @on-delete="deleteItem"
    :loading="deleteLoader"
  ></ConfirmDeleteModal>
</template>

<script lang="ts">
import { computed, onMounted, onBeforeMount, ref, watch } from "vue";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import CertificationModel from "@/modules/certification/components/CertificationModel.vue";
import CertificateEditModal from "@/modules/certification/components/CertificateEditModal.vue";
// import CommonChip from "@/modules/dashboard/components/Chips/CommonChip.vue";
import ConfirmDeleteModal from "@/modules/dashboard/components/Modals/ConfirmDeleteModal.vue";
import {
  UserRolesEnum,
  UserVerificationStatusEnum,
} from "@/core/enums/RolesEnum";
import { getImageStringToImageURL } from "@/core/utils/common";
import { WORKSTATION } from "@/store/modules/workstation";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import Loader from "@/core/components/common/Loader.vue";
import TradeWelcomeBoard from "./cards/TradeWelcomeBoard.vue";
import PendingVerifyNotice from "./cards/PendingVerifyNotice.vue";

export default {
  components: {
    CertificationModel,
    CertificateEditModal,
    ConfirmDeleteModal,
    UserProfileLogo,
    Loader,
    TradeWelcomeBoard,
    PendingVerifyNotice,
  },
  setup() {
    const dashBoardWelcomeButton = ref({
      text: "CREATE A Trades WORKSTATION",
      class: ["button", "button-purple-border"],
    }) as any;
    const store = useStore();
    const isShowCertificateModal = ref(false);
    const isEditCertificateModal = ref(false);

    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const userCertificates = ref([]) as any;
    const deleteLoader = ref(false);
    const isLoading = ref(false);
    const isUserProprtyOwner = computed(
      () => user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER
    );
    const getUserWorkStationFieldAccess = computed(
      () => store.getters[`${WORKSTATION}/getUserWorkStationFieldAccess`]
    );

    watch(
      () => store.getters[`${USER_STORE}/userCertificates`],
      async (certificates) => {
        userCertificates.value = certificates;
        await setProfileImageUrl();
      },
      {
        immediate: true,
      }
    );

    async function setProfileImageUrl() {
      for (const certificate of userCertificates.value) {
        for (const member of certificate.assignedUsers) {
          if (member.userPublicProfile.profileImage !== null) {
            member.userPublicProfile.publicUrl = await getImageStringToImageURL(
              member.userPublicProfile.profileImage
            );
          }
        }
      }
    }

    const userCertificateData = ref(null) as any;

    const isOpenConfirmDelete = ref(false);
    const confirmDeleteCertificate = ref(null) as any;
    const confirmDeleteAssignedMembers = ref(null) as any;

    onBeforeMount(async () => {
      try {
        isLoading.value = true;
        if (user.value?.id && !userCertificates.value?.length) {
          await store.dispatch(
            `${USER_STORE}/setUserCertificates`,
            user.value.id
          );
        }
      } catch (error) {
        console.log();
      } finally {
        isLoading.value = false;
      }
    });
    const onClickAddNewCertification = () => {
      isShowCertificateModal.value = true;
    };
    const onClickCloseModel = () => {
      isShowCertificateModal.value = false;
      clearData();
    };

    const onClickUpdateModal = async () => {
      await store.getters[`${USER_STORE}/userCertificates`];
      isEditCertificateModal.value = false;
      clearData();
    };

    const onClickCloseEditModal = () => {
      isEditCertificateModal.value = false;
      clearData();
    };

    const onDeleteItem = (certificate: any) => {
      confirmDeleteCertificate.value = certificate;
      isOpenConfirmDelete.value = true;
    };

    const onCloseConfirmDelete = () => {
      confirmDeleteCertificate.value = null;
      isOpenConfirmDelete.value = false;
    };
    const deleteCertificate = async (certificate: any) => {
      try {
        await store.dispatch(`${USER_STORE}/removeUserCertificates`, {
          userId: user?.value?.id,
          userCertificateId: certificate.id,
        });

        // Delete related Members
        // let payload = {
        //   teamMemberIds: members,
        // };

        // if (members?.length) {
        //   await store.dispatch(
        //     `${USER_STORE}/removeCertificateRelatedMembers`,
        //     {
        //       userId: user?.value?.id,
        //       teamMemberIds: payload,
        //     }
        //   );
        // }

        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };

    const deleteItem = async () => {
      deleteLoader.value = true;
      await deleteCertificate(confirmDeleteCertificate.value);
      deleteLoader.value = false;
      onCloseConfirmDelete();
    };

    const editCertificate = (certificate: any) => {
      isEditCertificateModal.value = true;
      userCertificateData.value = certificate;
    };

    const clearData = () => {
      userCertificateData.value = null;
    };

    const chipLabel = (status: any) => {
      if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
        return "Pending";
      else if (UserVerificationStatusEnum.REJECTED === status)
        return "Rejected";
      else if (UserVerificationStatusEnum.VERIFIED === status)
        return "Verified";
      else if (UserVerificationStatusEnum.NOT_ADDED === status)
        return "Not added";
    };
    const chipColor = (status: any) => {
      if (UserVerificationStatusEnum.PENDING_VERIFICATION === status)
        return "#FAA500";
      else if (UserVerificationStatusEnum.REJECTED === status) return "red";
      else if (UserVerificationStatusEnum.VERIFIED === status) return "green";
      else if (UserVerificationStatusEnum.NOT_ADDED === status)
        return "#7467B7";
    };

    const isWorkStationVerified = computed(() => {
      return (
        store.getters[`${WORKSTATION}/activeUserWorkstation`]
          ?.verificationStatus === UserVerificationStatusEnum.VERIFIED
      );
    });
    const isShowPendingBoard = computed(
      () =>
        isWorkStationVerified.value &&
        userCertificates.value?.some(
          (certificates: any) =>
            certificates?.userVerificationStatus ===
            UserVerificationStatusEnum.PENDING_VERIFICATION
        ) &&
        userCertificates.value?.some(
          (certificates: any) =>
            certificates?.userVerificationStatus ===
            UserVerificationStatusEnum.VERIFIED
        )
    );
    return {
      dashBoardWelcomeButton,
      onClickAddNewCertification,
      userCertificates,
      deleteCertificate,
      isShowCertificateModal,
      isEditCertificateModal,
      onClickCloseModel,
      onClickCloseEditModal,
      editCertificate,
      userCertificateData,
      chipLabel,
      chipColor,
      UserVerificationStatusEnum,
      clearData,
      getUserWorkStationFieldAccess,
      isOpenConfirmDelete,
      deleteItem,
      onDeleteItem,
      onCloseConfirmDelete,
      onClickUpdateModal,
      confirmDeleteCertificate,
      deleteLoader,
      isLoading,
      isUserProprtyOwner,
      isShowPendingBoard,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/trade-passport/styles/TradePassport.scss";
</style>
